export default {

    // Display Actions
    ROUTE_ASSET_MGMT_QUERY_ROUTE_ASSETS_STARTING: 'ROUTE_ASSET_MGMT_ROUTE_ASSETS_STARTING',
    ROUTE_ASSET_MGMT_QUERY_ROUTE_ASSETS_SUCCESS: 'ROUTE_ASSET_MGMT_ROUTE_ASSETS_SUCCESS',
    ROUTE_ASSET_MGMT_QUERY_ROUTE_ASSETS_ERROR: 'ROUTE_ASSET_MGMT_ROUTE_ASSETS_ERROR',
    ROUTE_ASSET_MGMT_QUERY_CONSUMERS_STARTING: 'ROUTE_ASSET_MGMT_CONSUMERS_STARTING',
    ROUTE_ASSET_MGMT_QUERY_CONSUMERS_SUCCESS: 'ROUTE_ASSET_MGMT_CONSUMERS_SUCCESS',
    ROUTE_ASSET_MGMT_QUERY_CONSUMERS_ERROR: 'ROUTE_ASSET_MGMT_CONSUMERS_ERROR',
    ROUTE_ASSET_MGMT_QUERY_TRUCKS_STARTING: 'ROUTE_ASSET_MGMT_TRUCKS_STARTING',
    ROUTE_ASSET_MGMT_QUERY_TRUCKS_SUCCESS: 'ROUTE_ASSET_MGMT_TRUCKS_SUCCESS',
    ROUTE_ASSET_MGMT_QUERY_TRUCKS_ERROR: 'ROUTE_ASSET_MGMT_TRUCKS_ERROR',
    ROUTE_ASSET_MGMT_SET_CURRENT_PAGE: 'ROUTE_ASSET_MGMT_SET_CURRENT_PAGE',
    ROUTE_ASSET_MGMT_SET_ROUTE_ASSETS_PER_PAGE: 'ROUTE_ASSET_MGMT_SET_ROUTE_ASSETS_PER_PAGE',
    ROUTE_ASSET_MGMT_OPEN_ADD_ROUTE_ASSET_DIALOG: 'ROUTE_ASSET_MGMT_OPEN_ADD_ROUTE_ASSET_DIALOG',
    ROUTE_ASSET_MGMT_OPEN_ADD_CONSUMER_DIALOG: 'ROUTE_ASSET_MGMT_OPEN_ADD_CONSUMER_DIALOG',
    ROUTE_ASSET_MGMT_RESET_WORKING_ROUTE_ASSET: 'ROUTE_ASSET_MGMT_RESET_WORKING_ROUTE_ASSET',

    // Route Asset add/edit and Consumer add dialog actions
    ROUTE_ASSET_MGMT_UPDATE_ROUTE_ASSET_STARTING: 'ROUTE_ASSET_MGMT_UPDATE_ROUTE_ASSET_STARTING',
    ROUTE_ASSET_MGMT_UPDATE_ROUTE_ASSET_SUCCESS: 'ROUTE_ASSET_MGMT_UPDATE_ROUTE_ASSET_SUCCESS',
    ROUTE_ASSET_MGMT_UPDATE_ROUTE_ASSET_ERROR: 'ROUTE_ASSET_MGMT_UPDATE_ROUTE_ASSET_ERROR',
    ROUTE_ASSET_MGMT_ADD_ROUTE_ASSET_STARTING: 'ROUTE_ASSET_MGMT_ADD_ROUTE_ASSET_STARTING',
    ROUTE_ASSET_MGMT_SAVE_ROUTE_ASSET_SUCCESS: 'ROUTE_ASSET_MGMT_SAVE_ROUTE_ASSET_SUCCESS',
    ROUTE_ASSET_MGMT_ADD_ROUTE_ASSET_ERROR: 'ROUTE_ASSET_MGMT_ADD_ROUTE_ASSET_ERROR',
    ROUTE_ASSET_MGMT_ADD_CONSUMER_STARTING: 'ROUTE_ASSET_MGMT_ADD_CONSUMER_STARTING',
    ROUTE_ASSET_MGMT_ADD_CONSUMER_SUCCESS: 'ROUTE_ASSET_MGMT_ADD_CONSUMER_SUCCESS',
    ROUTE_ASSET_MGMT_ADD_CONSUMER_ERROR: 'ROUTE_ASSET_MGMT_ADD_CONSUMER_ERROR',    
    ROUTE_ASSET_MGMT_CLOSE_ADD_ROUTE_ASSET_DIALOG: 'ROUTE_ASSET_MGMT_CLOSE_ADD_ROUTE_ASSET_DIALOG',
    ROUTE_ASSET_MGMT_CLOSE_ADD_CONSUMER_DIALOG: 'ROUTE_ASSET_MGMT_CLOSE_ADD_CONSUMER_DIALOG',
    ROUTE_ASSET_MGMT_CLOSE_EDIT_DIALOG: 'ROUTE_ASSET_MGMT_CLOSE_EDIT_DIALOG',
    ROUTE_ASSET_MGMT_SET_WORKING_ROUTE_ASSET: 'ROUTE_ASSET_MGMT_SET_WORKING_ROUTE_ASSET',
    ROUTE_ASSET_MGMT_SET_WORKING_ROUTE_ASSET_CONSUMER_NAME: 'ROUTE_ASSET_MGMT_SET_WORKING_ROUTE_ASSET_CONSUMER_NAME',
    ROUTE_ASSET_MGMT_SET_WORKING_CONSUMER_NAME: 'ROUTE_ASSET_MGMT_SET_WORKING_CONSUMER_NAME',
    ROUTE_ASSET_MGMT_SET_WORKING_DESTINATION_PROJECT: 'ROUTE_ASSET_MGMT_SET_WORKING_DESTINATION_PROJECT',
    ROUTE_ASSET_MGMT_SET_WORKING_DESTINATION_PATH: 'ROUTE_ASSET_MGMT_SET_WORKING_DESTINATION_PATH',
    ROUTE_ASSET_MGMT_SET_WORKING_TRUCK_NAME: 'ROUTE_ASSET_MGMT_SET_WORKING_TRUCK_NAME',
    ROUTE_ASSET_MGMT_ROUTE_ASSET_SET_WORKING_CONSUMER_NAME: 'ROUTE_ASSET_MGMT_ROUTE_ASSET_SET_WORKING_CONSUMER_NAME',
    ROUTE_ASSET_MGMT_VALIDATE_WORKING_CONSUMER: 'ROUTE_ASSET_MGMT_VALIDATE_WORKING',
    ROUTE_ASSET_MGMT_OPEN_EDIT_DIALOG: 'ROUTE_ASSET_MGMT_OPEN_EDIT_DIALOG',
    ROUTE_ASSET_MGMT_RESET_WORKING_CONSUMER: 'ROUTE_ASSET_MGMT_RESET_WORKING_CONSUMER',

    // Delete dialog actions
    ROUTE_ASSET_MGMT_OPEN_DELETE_CONFIRMATION: 'ROUTE_ASSET_MGMT_OPEN_DELETE_CONFIRMATION',
    ROUTE_ASSET_MGMT_DELETE_ROUTE_ASSET_STARTING: 'ROUTE_ASSET_MGMT_DELETE_ROUTE_ASSET_STARTING',
    ROUTE_ASSET_MGMT_DELETE_ROUTE_ASSET_SUCCESS: 'ROUTE_ASSET_MGMT_DELETE_ROUTE_ASSET_SUCCESS',
    ROUTE_ASSET_MGMT_DELETE_ROUTE_ASSET_ERROR: 'ROUTE_ASSET_MGMT_DELETE_ROUTE_ASSET_ERROR',
    ROUTE_ASSET_MGMT_CLOSE_DELETE_CONFIRMATION: 'ROUTE_ASSET_MGMT_CLOSE_DELETE_CONFIRMATION',

    // Set active state actions
    ROUTE_ASSET_MGMT_SET_ACTIVE_STARTING: 'ROUTE_ASSET_MGMT_SET_ACTIVE_STARTING',
    ROUTE_ASSET_MGMT_SET_ACTIVE_SUCCESS: 'ROUTE_ASSET_MGMT_SET_ACTIVE_SUCCESS',
    ROUTE_ASSET_MGMT_SET_ACTIVE_ERROR: 'ROUTE_ASSET_MGMT_SET_ACTIVE_ERROR',
}