import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Typography } from "@mui/material";
import _ from "lodash";
import React, { Component } from "react";
import { compose, withProps } from "recompose";
import { connect } from "react-redux";
import componentTypes from "../../componentTypes";
import { routeAssetMgmtState } from "../../../state/displays/routeAssetManagement/routeAssetMgmtSelectors";
import * as routeAssetMgmtDeleteDialogActions from "../../../state/displays/routeAssetManagement/actions/routeAssetMgmtDeleteDialogActions";

class RouteAssetMgmtDeleteConfirmationDialog extends Component {

    render() {
        return (
            <Dialog aria-modal open={this.props.openDeleteRouteAssetUI} disableEscapeKeyDown>
                <DialogTitle>Delete Asset Routing</DialogTitle>
                <DialogContent>
                    <DialogContentText>Delete the following Asset Routing?</DialogContentText>
                    <Typography>{this.props.workingRouteAsset.truckName?.label}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.closeDeleteConfirmation} color='inherit'>Cancel</Button>
                    <Button onClick={() => this.props.deleteRouteAsset(this.props.workingRouteAsset.truckName.value)} color='primary'>Delete</Button>
                </DialogActions>
            </Dialog>
        );
    }

}

const stateDefinition = (props) => {
    return {
        stateDef: {
            key: _.isNil(props.stateKey) ? componentTypes.ROUTE_ASSET_MANAGEMENT : props.stateKey,
            type: componentTypes.ROUTE_ASSET_MANAGEMENT,
        }
    };
};

const mapStateToProps = (state, props) => {
    const { stateDef } = props;
    let componentState = routeAssetMgmtState(state[stateDef.key]);
    return {
        openDeleteRouteAssetUI: componentState.openDeleteRouteAssetUI,
        workingRouteAsset: componentState.workingRouteAsset,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        closeDeleteConfirmation: () => dispatch(routeAssetMgmtDeleteDialogActions.closeDeleteConfirmation(props.stateDef)),
        deleteRouteAsset: (truckName) => dispatch(routeAssetMgmtDeleteDialogActions.deleteRouteAsset(props.stateDef, truckName))
    };
};

export default compose(
    withProps(stateDefinition)
)(connect(mapStateToProps, mapDispatchToProps)(RouteAssetMgmtDeleteConfirmationDialog));