import { Dialog, DialogActions, DialogContent, TextField, Button, DialogTitle } from "@mui/material";
import _ from "lodash";
import { Component } from "react";
import { connect } from "react-redux";
import { compose, withProps } from "recompose";
import componentTypes from "../../componentTypes";
import { routeAssetMgmtState } from "../../../state/displays/routeAssetManagement/routeAssetMgmtSelectors";
import * as routeAssetMgmtConsumerDialogActions from "../../../state/displays/routeAssetManagement/actions/routeAssetMgmtConsumerDialogActions";

class RouteAssetMgmtConsumerDialog extends Component {

    componentDidUpdate(prevProps) {
        if (!prevProps.openAddConsumerUI && this.props.openAddConsumerUI) {
            this.props.validateWorkingConsumer();
        }
    }

    render(){
        return (
            <Dialog 
                aria-modal
                open={this.props.openAddConsumerUI}
                disableEscapeKeyDown
            >
                <DialogTitle>Add New Consumer</DialogTitle>
                <DialogContent>
                    <TextField 
                        sx={{marginTop: 2}}
                        autoFocus 
                        required 
                        variant='standard' 
                        size="small" 
                        helperText="Consumer Name" 
                        fullWidth 
                        inputProps={{maxLength:60}} 
                        error={this.props.workingConsumerValidation.hasErrorConsumerName} 
                        value={_.isNil(this.props.workingConsumer.consumerName) ? '' : this.props.workingConsumer.consumerName.value} 
                        onChange={(event) => this.props.setWorkingConsumerName(event.target.value)} 
                        onBlur={() => this.props.validateWorkingConsumer()} 
                    />
                    <TextField 
                        sx={{marginTop: 2}}
                        required
                        variant="standard"
                        size="small"
                        helperText="Destination Project"
                        fullWidth
                        inputProps={{maxLength:60}}
                        error={this.props.workingConsumerValidation.hasErrorDestinationProject} 
                        value={this.props.workingConsumer.destinationProject} 
                        onChange={(event) => this.props.setWorkingDestinationProject(event.target.value)} 
                        onBlur={() => this.props.validateWorkingConsumer()} 
                    />
                    <TextField 
                        sx={{marginTop: 2}}
                        required
                        variant="standard"
                        size="small"
                        helperText="Destination Path"
                        fullWidth
                        inputProps={{maxLength:60}}
                        error={this.props.workingConsumerValidation.hasErrorDestinationPath} 
                        value={this.props.workingConsumer.destinationPath} 
                        onChange={(event) => this.props.setWorkingDestinationPath(event.target.value)} 
                        onBlur={() => this.props.validateWorkingConsumer()} 
                    />
                </DialogContent>
                <DialogActions>
                    <Button color='inherit' onClick={this.props.closeAddDialog}>Cancel</Button> 
                    <Button 
                        disabled={this.props.workingConsumerValidation.hasErrors} 
                        onClick={() => { 
                            this.props.addConsumer(this.props.workingConsumer); 
                        }} 
                        color="primary"
                    >
                        Add
                    </Button> 
                </DialogActions>
            </Dialog>
        )
    }
};

const stateDefinition = (props) => {
    return {
        stateDef: {
            key: _.isNil(props.stateKey) ? componentTypes.ROUTE_ASSET_MANAGEMENT : props.stateKey,
            type: componentTypes.ROUTE_ASSET_MANAGEMENT,
        }
    }
}; 

const mapStateToProps = (state, props) => {
    const { stateDef } = props; 
    let componentState = routeAssetMgmtState(state[stateDef.key]);
    return {
        openAddConsumerUI: componentState.openAddConsumerUI,
        workingConsumer: componentState.workingConsumer,
        workingConsumerValidation: componentState.workingConsumerValidation
    }
}; 

const mapDispatchToProps = (dispatch, props) => {
    return {
        closeAddDialog: () => dispatch(routeAssetMgmtConsumerDialogActions.closeAddDialog(props.stateDef)),
        openAddDialog: () => dispatch(routeAssetMgmtConsumerDialogActions.openAddDialog(props.stateDef)),
        setWorkingConsumerName: (consumerName) => dispatch(routeAssetMgmtConsumerDialogActions.setWorkingConsumerName(props.stateDef, consumerName)),
        setWorkingDestinationProject: (destinationProject) => dispatch(routeAssetMgmtConsumerDialogActions.setWorkingDestinationProject(props.stateDef, destinationProject)),
        setWorkingDestinationPath: (destinationPath) => dispatch(routeAssetMgmtConsumerDialogActions.setWorkingDestinationPath(props.stateDef, destinationPath)),
        validateWorkingConsumer: () => dispatch(routeAssetMgmtConsumerDialogActions.validateWorkingConsumer(props.stateDef)),
        addConsumer: (workingConsumer) => dispatch(routeAssetMgmtConsumerDialogActions.addConsumer(props.stateDef, workingConsumer)),
    }
};

export default compose(
    withProps(stateDefinition)
)(connect(mapStateToProps, mapDispatchToProps)(RouteAssetMgmtConsumerDialog));