import useSimulationData from "../../../helpers/simulationHelper";
import { performDisplayFetch } from "../../common/fetch";
import { consumers, routeAssets, trucks } from "./routeAssetSimulation";

const fetchRouteAssets = () => {
    return new Promise((resolve) => {
        if (useSimulationData('routeAssetMgmt')){
            resolve(routeAssets);
        } else {
            resolve(performDisplayFetch({
                'query': 'query { routedAssetsForOwner { id truckName consumerName destinationPath destinationProject consumerId } }'
            }));
        }
    });
};

const fetchConsumers = () => {
    return new Promise((resolve) => {
        if (useSimulationData('routeAssetMgmt')){
            resolve(consumers); 
        } else {
            resolve(performDisplayFetch({
                'query': 'query { consumersForOwner { id consumerName destinationPath destinationProject } }'
            }));
        }
    });
}; 

const fetchAddRouteAsset = (workingRouteAsset) => {
    return new Promise((resolve) => {
        if (useSimulationData('routeAssetMgmt')){
            let newRouteAsset = {
                ...workingRouteAsset,
                id: routeAssets.length + 1,
            }; 
            let newRouteAssets = _.cloneDeep(routeAssets);
            newRouteAssets.push(newRouteAsset);
            resolve(newRouteAssets);
        } else {
            resolve(performDisplayFetch({
                'query': 'mutation routeAsset($asset:RoutedAssetInput!) { routeAsset(asset: $asset) { id truckName consumerName destinationPath destinationProject consumerId } }',
                'variables': {
                  'asset': workingRouteAsset
                }
            }));
        }
    });
};

const fetchAddConsumer = (workingConsumer) => {
    return new Promise((resolve) => {
        if (useSimulationData('routeAssetMgmt')){
            resolve(routeAssets);
        } else {
            resolve(performDisplayFetch({
                'query': 'mutation saveConsumer($input:ConsumerInput!) { saveConsumer(input: $input) { id consumerName destinationPath destinationProject } }',
                'variables': {
                  'input': workingConsumer
                }
            }));
        }
    });
};

const fetchUpdateRouteAsset = (workingRouteAsset) => {
    return new Promise((resolve) => {
        if (useSimulationData('routeAssetMgmt')){
            const index = routeAssets.findIndex(asset => asset.id === workingRouteAsset.id);
            if (index !== -1) {
                routeAssets[index] = workingRouteAsset;
            }
            resolve(routeAssets);
        } else {
          resolve(performDisplayFetch({
            'query': 'mutation routeAsset($asset:RoutedAssetInput!) { routeAsset(asset: $asset) { id truckName consumerName destinationPath destinationProject consumerId } }',
            'variables': {
              'asset': workingRouteAsset
            }
          }));
        }
    });
};

const fetchDeleteRouteAsset = (truckName) => {
    return new Promise((resolve) => {
        if (useSimulationData('routeAssetMgmt')){
            const index = routeAssets.findIndex(asset => asset.id === id);
            if (index !== -1) {
                routeAssets.splice(index, 1);
            }
            resolve(routeAssets);
        } else {
            resolve(performDisplayFetch({
                'query': 'mutation stopRoutingAsset($truckName: String!) { stopRoutingAsset(truckName: $truckName) { success } }',
                'variables': {
                  'truckName': truckName
                }
            }));
        }
    });
};


const fetchTrucks = () => {
    return new Promise((resolve) => {
        if (useSimulationData('routeAssetMgmt')){
            resolve(trucks);
        } else {
            resolve(performDisplayFetch({
                'query': 'query { truckNamesByOwner { name } }'
            }));
        }
    });
};

export {
    fetchRouteAssets, 
    fetchAddRouteAsset,
    fetchAddConsumer,
    fetchUpdateRouteAsset,
    fetchDeleteRouteAsset,
    fetchConsumers,
    fetchTrucks,
}