import { handleError } from "../../../app/actions/appErrorActions";
import errorMessages from "../../../common/errorMessages";
import defaultActionFactory from "../../../common/factories/defaultActionFactory";
import routeAssetMgmtActionTypes from "../routeAssetMgmtActionTypes";
import { fetchDeleteRouteAsset } from "../routeAssetMgmtQueries";
import { queryRouteAssets } from "./routeAssetMgmtDisplayActions";

const closeDeleteConfirmation = defaultActionFactory(routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_CLOSE_DELETE_CONFIRMATION, 'stateDef');
const deleteRouteAssetStarting = defaultActionFactory(routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_DELETE_ROUTE_ASSET_STARTING, 'stateDef');
const deleteRouteAssetSuccess = defaultActionFactory(routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_DELETE_ROUTE_ASSET_SUCCESS, 'stateDef', 'truckName');
const deleteRouteAssetError = defaultActionFactory(routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_DELETE_ROUTE_ASSET_ERROR, 'stateDef');

const deleteRouteAsset = (stateDef, truckName) => {
    return async (dispatch) => {
        try {
            await dispatch(deleteRouteAssetStarting(stateDef));
            await fetchDeleteRouteAsset(truckName);
            await dispatch(deleteRouteAssetSuccess(stateDef, truckName));
        } catch (e) {
            await dispatch(deleteRouteAssetError(stateDef));
            await dispatch(handleError(errorMessages.ERROR_DELETING_ROUTE_ASSET, e.message));
        }
        return dispatch(queryRouteAssets(stateDef));
    };
};

export {
    deleteRouteAsset,
    deleteRouteAssetStarting,
    deleteRouteAssetSuccess,
    deleteRouteAssetError,
    closeDeleteConfirmation
};