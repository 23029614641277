import routeAssetMgmtActionTypes from "../routeAssetMgmtActionTypes";
import { routeAssetMgmtState } from "../routeAssetMgmtSelectors";

const initialState = routeAssetMgmtState();

const routeAssetMgmtConsumerDialogReducer = (state, action) => {
    switch(action.type){
        case routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_ADD_CONSUMER_STARTING:
            return { ...state, queryRunning: true };
        case routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_ADD_CONSUMER_SUCCESS:
            return { ...state, queryRunning: false, openAddConsumerUI: false };
        case routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_ADD_CONSUMER_ERROR:
            return { ...state, queryRunning: false };
        case routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_CLOSE_ADD_CONSUMER_DIALOG:
            return { ...state, openAddConsumerUI: false };
        case routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_SET_WORKING_CONSUMER_NAME:
            return { 
                ...state, 
                workingConsumer: { 
                    ...state.workingConsumer, 
                    consumerName: _.isEmpty(action.consumerName) ? null : { value: action.consumerName, label: action.consumerName } 
                }
            };
        case routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_SET_WORKING_DESTINATION_PATH:
            return { 
                ...state, 
                workingConsumer: { 
                    ...state.workingConsumer, 
                    destinationPath: action.destinationPath 
                }
            };
        case routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_SET_WORKING_DESTINATION_PROJECT:
            return { 
                ...state, 
                workingConsumer: { 
                    ...state.workingConsumer, 
                    destinationProject: action.destinationProject 
                }
            };
        case routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_VALIDATE_WORKING_CONSUMER:
            const { consumerName, destinationPath, destinationProject } = state.workingConsumer;
            const hasErrorConsumerName = _.isNil(consumerName);
            const hasErrorDestinationProject = !destinationProject;
            const hasErrorDestinationPath = !destinationPath;
            const hasErrors = hasErrorConsumerName || hasErrorDestinationProject || hasErrorDestinationPath;

            return { 
                ...state, 
                workingConsumerValidation: {
                    hasErrors,
                    hasErrorConsumerName,
                    hasErrorDestinationProject,
                    hasErrorDestinationPath,
                }
            };
        case routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_OPEN_ADD_CONSUMER_DIALOG: 
            return { ...state, openAddConsumerUI: true };
        case routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_RESET_WORKING_CONSUMER:
            return { 
                ...state, 
                workingConsumer: initialState.workingConsumer,
                workingConsumerValidation: initialState.workingConsumerValidation
            };
        default: 
            return state; 
    }
};

export default routeAssetMgmtConsumerDialogReducer;