import React, {Component} from 'react';
import _ from "lodash";
import {compose, withProps} from "recompose";
import {connect} from "react-redux";
import {
  Box, 
  Dialog, 
  DialogContent, 
  Tab, 
  Tabs,   
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  DialogActions,
  DialogTitle,
  TextField,
  Button,
  IconButton,
  Backdrop,
  Tooltip,
  Alert
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import {fleetMgmtState} from "../../../state/displays/fleetManagement/fleetMgmtSelectors";
import * as fleetMgmtActions from "../../../state/displays/fleetManagement/fleetMgmtActions";
import ComponentTypes from "../../componentTypes";
import {AutoCompleteMDT} from "../../controls/mdtMuiControls";
import getDialogStyles from "../../common/styles/dialogStyles";
import {fleetTypes, getFleetTypeNameByType} from "../../../state/common/services/fleetTypeService";
import {fleetDivisions} from "../../../state/common/services/fleetDivisionService";
import {appState as applicationState} from "../../../state/app/appSelectors";
import {isValidPort, isValidURL} from "../../../state/displays/fleetManagement/services/fleetMgmtService";

import SplitButton from '../../controls/splitButton/splitButton';

const divisionOptions = Object.values(fleetDivisions).map(option => {
    return {
        label: option
    };
});
const typeOptions = Object.values(fleetTypes).map(type => {
    return {
        label: getFleetTypeNameByType(type),
        value: type
    };
});
const dialogStyles = getDialogStyles();

const styles = {
    formField: {
        marginTop: 2
    }
};

const definitionTabContent = (props) => {
  return (
    <Box sx={{display: 'flex', flexFlow: 'column nowrap', width: '100%'}}>
      <TextField
          sx={styles.formField}
          autoFocus
          variant='standard'
          size='small'
          helperText="Fleet Name"
          inputProps={{maxLength: 255}}
          value={props.fleetToSave.fleetName || ''}
          onChange={(event) => {
              props.onChangeFleet('fleetName', event.target.value);
          }}
          error={props.errorsOnFleetSave.hasFleetNameError}
          fullWidth
      />
      <TextField
          sx={styles.formField}
          variant='standard'
          size='small'
          helperText={props.datavanNameHelperText}
          inputProps={{maxLength: 255}}
          value={props.fleetToSave.datavan || ''}
          onChange={(event) => {
              props.onChangeFleet('datavan', event.target.value);
          }}
          onBlur={(event) => {
            props.onBlurDatavan(event.target.value, props.ownerId);
          }}
          error={props.errorsOnFleetSave.hasDatavanError || props.warningDatavanOnFleetSave}
          fullWidth
      />
      <TextField
          sx={styles.formField}
          variant='standard'
          size='small'
          helperText="Region"
          inputProps={{maxLength: 255}}
          value={props.fleetToSave.region || ''}
          onChange={(event) => {
              props.onChangeFleet('region', event.target.value);
          }}
          fullWidth
      />
      <Box>
          <AutoCompleteMDT
              sx={{...styles.formField, width: '200px'}}
              options={divisionOptions}
              value={props.fleetToSave.division || null}
              noOptionsText={'No divisions found...'}
              renderInput={(params) => <TextField {...params} variant="standard" helperText="Division"
                                                  error={props.errorsOnFleetSave.hasDivisionError}
                                                  inputProps={{ ...params.inputProps, readOnly: true }}/>}
              onChange={(event, value, reason) => {
                  props.onChangeFleet('division', value.label);
              }}
              isOptionEqualToValue={(option, value) => option.label == value}
          />
      </Box>
      <Box>
          <AutoCompleteMDT
              sx={{...styles.formField, width: '300px'}}
              options={typeOptions}
              value={typeOptions.find(option => option.value === props.fleetToSave.type)?.label || null}
              noOptionsText={'No types found...'}
              renderInput={(params) => <TextField {...params} variant="standard" helperText="Type"
                                                  error={props.errorsOnFleetSave.hasTypeError}
                                                  inputProps={{ ...params.inputProps, readOnly: true }}/>}
              onChange={(event, value, reason) => {
                  props.onChangeFleet('type', value.value);
              }}
              isOptionEqualToValue={(option, value) => option.label == value}
          />
      </Box>
    </Box>
  );
}

const datasourcesTabContent = (props) => {
  return (
    <Box sx={{display: 'flex', flexFlow: 'column nowrap', width: '100%', height: '100%', flexGrow: 1}}>
      {
        props.datasources.length === 0 &&
        <Box sx={{display: 'flex', flexFlow: 'column nowrap', width: '100%', height: '100%', flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
          <Typography variant='h6'>No Datasources Configured</Typography>
          <Typography variant='subtitle2'>Please Add a Datasource to this Fleet</Typography>
        </Box>  
      }
      {
        props.datasources.length > 0 &&
        <>
        {
          _.map(props.datasources, (datasource, index) => {
            return (
              <Accordion key={index} disableGutters expanded={datasource.expanded}>
                <AccordionSummary key={index} expandIcon={<ExpandMoreIcon />} onClick={(event) => { props.onExpandDatasource(index); }}>
                  <Box sx={{display: 'flex', flexFlow: 'row nowrap', flexGrow: 1}}>
                    <Tooltip title={datasource.hasDuplicateNameError === true ? 'Cannot have duplicate names' : ''}>
                      <TextField 
                        sx={{wdith: (datasource.delete === false ? '90%' : '70%')}}
                        variant='standard' 
                        fullWidth
                        value={datasource.name}
                        onClick={(event) => { event.stopPropagation(); }}
                        onChange={(event, value, reason) => { props.onUpdateDatasource(index, 'name', event.target.value ); }}
                        inputProps={{maxLength:255, sx: { textDecoration: (datasource.willDelete === true ? 'line-through' : 'none') }}}
                        InputProps={{disableUnderline: ((_.isEmpty(datasource.name) || datasource.hasDuplicateNameError === true) ? false : true) }}
                        error={_.isEmpty(datasource.name) || datasource.hasDuplicateNameError === true} 
                        disabled={datasource.delete === true || datasource.willDelete === true}
                      />
                    </Tooltip>
                    {
                      datasource.delete === false &&
                      <Tooltip title={'Delete this Datasource'}>
                        <IconButton size='small' onClick={(event) => { props.onRemoveDatasource(index); event.stopPropagation(); }}>
                          <DeleteOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    }
                    {
                      datasource.delete === true &&
                      <Button variant='text' size='small' sx={{width: '30%'}} onClick={(event) => { props.onRestoreDatasource(index); event.stopPropagation(); }}>
                        Undo Delete
                      </Button>
                    }
                  </Box>
                </AccordionSummary>
                <AccordionDetails sx={{paddingTop: 0}}>
                  {
                    datasource.delete === true &&
                    datasource.willDelete === false && 
                    <Backdrop sx={{position: 'absolute', zIndex: 1, marginTop: '50px', background: 'rgba(48,48,48,0.9)'}} open={true}>
                      <Box sx={{display: 'flex', flexFlow: 'column nowrap', width: '90%', justifyContent: 'center', alignItems: 'center'}}>
                        <Typography variant='subtitle2'>Deleting this datasource will stop its data from being uploaded.</Typography>
                        <Typography variant='subtitle2'>Type DELETE to continue with deleting this datasource.</Typography>
                        <TextField
                          sx={{width: '50%'}}
                          variant='standard' 
                          fullWidth
                          value={datasource.deleteValidation}
                          onChange={(event, value, reason) => { props.onConfirmRemoveDatasource(index, event.target.value); }}
                          inputProps={{maxLength:255, sx: {textAlign: 'center'}}}
                        />
                      </Box>
                    </Backdrop>
                  }
                  <Box sx={{display: 'flex', flexFlow: 'column nowrap', width: '100%'}}>
                    <TextField 
                      variant='standard' 
                      fullWidth
                      value={datasource.databaseName}
                      onChange={(event, value, reason) => { props.onUpdateDatasource(index, 'databaseName', event.target.value ); }}
                      inputProps={{maxLength:255}}
                      helperText='Database Name'
                      error={_.isEmpty(datasource.databaseName)} 
                      disabled={datasource.willDelete === true} 
                    />
                    <Box sx={{display: 'flex', flexFlow: 'row nowrap'}}>
                      <Tooltip disableInteractive title={isValidURL(datasource.databaseHostIP) ? '' : 'Database Host IP is not valid'}>
                        <TextField 
                          sx={{width: '70%'}}
                          variant='standard' 
                          fullWidth
                          value={datasource.databaseHostIP}
                          onChange={(event, value, reason) => { props.onUpdateDatasource(index, 'databaseHostIP', event.target.value ); }}
                          inputProps={{maxLength:255}}
                          helperText='Database Host IP'
                          error={_.isEmpty(datasource.databaseHostIP) || !isValidURL(datasource.databaseHostIP)} 
                          disabled={datasource.willDelete === true} 
                        />
                      </Tooltip>
                      <Tooltip disableInteractive title={isValidPort(datasource.databaseHostPort) ? '' : 'Database Host Port is not valid'}>
                        <TextField 
                          sx={{width: '30%'}}
                          variant='standard' 
                          fullWidth
                          value={datasource.databaseHostPort}
                          onChange={(event, value, reason) => { props.onUpdateDatasource(index, 'databaseHostPort', event.target.value ); }}
                          inputProps={{maxLength:255}}
                          helperText='Database Host Port'
                          error={_.isEmpty(datasource.databaseHostPort) || !isValidPort(datasource.databaseHostPort)} 
                          disabled={datasource.willDelete === true} 
                        />
                      </Tooltip>
                    </Box>
                    <Box sx={{display: 'flex', flexFlow: 'row nowrap'}}>
                      <Tooltip disableInteractive title={isValidURL(datasource.registrationServerHostIP) ? '' : 'Registration Server Host IP is not valid'}>
                        <TextField 
                          sx={{width: '70%'}}
                          variant='standard' 
                          fullWidth
                          value={datasource.registrationServerHostIP}
                          onChange={(event, value, reason) => { props.onUpdateDatasource(index, 'registrationServerHostIP', event.target.value ); }}
                          inputProps={{maxLength:255}}
                          helperText='Registration Server Host IP'
                          error={_.isEmpty(datasource.registrationServerHostIP) || !isValidURL(datasource.registrationServerHostIP)} 
                          disabled={datasource.willDelete === true} 
                        />
                      </Tooltip>
                      <Tooltip disableInteractive title={isValidPort(datasource.registrationServerHostPort) ? '' : 'Registration Server Host Port is not valid'}>
                        <TextField 
                          sx={{width: '30%'}}
                          variant='standard' 
                          fullWidth
                          value={datasource.registrationServerHostPort}
                          onChange={(event, value, reason) => { props.onUpdateDatasource(index, 'registrationServerHostPort', event.target.value ); }}
                          inputProps={{maxLength:255}}
                          helperText='Registration Server Host Port'
                          error={_.isEmpty(datasource.registrationServerHostPort) || !isValidPort(datasource.registrationServerHostPort)} 
                          disabled={datasource.willDelete === true} 
                        />
                      </Tooltip>
                    </Box>
                    <Box sx={{display: 'flex', flexFlow: 'row nowrap'}}>
                      <TextField 
                        sx={{width: '90%'}}
                        variant='standard' 
                        fullWidth
                        value={datasource.datasourceGuid}
                        inputProps={{maxLength:255}}
                        helperText='Datasource GUID'
                        disabled={true}
                        error={_.isEmpty(datasource.datasourceGuid)} 
                      />
                      <Tooltip title={'Generate a new GUID for this datasource'}>
                        <IconButton sx={{width: '10%'}} disabled={datasource.willDelete === true} onClick={(event) => { props.onUpdateDatasource(index, 'datasourceGuid', '' );}}>
                          <RefreshIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <AutoCompleteMDT 
                      sx={{marginTop: 1, width: '100%'}}
                      options={props.datasourceSourceTypes}
                      value={datasource.sourceType}
                      noOptionsText={'No SourceTypes found...'}
                      onChange={(event, value, reason) => {
                        props.onUpdateDatasource(index, 'sourceType', value);
                      }}
                      renderInput={(params) => <TextField {...params} variant="standard" helperText="Source Type"/>}
                      disabled={datasource.willDelete === true} 
                    />

                  </Box>
                </AccordionDetails>
              </Accordion>
            )
          })
        }
        </>
      }
    </Box>
  )
}

class SaveFleetDialog extends Component {

    constructor(props) {
        super(props);
    }

    shouldDisableSaveButton = () => {
      if (!_.isEmpty(Object.keys(this.props.errorsOnFleetSave)) || (this.props.datasourcesChanged === false && this.props.fleetHasChanges === false)) {
        return true;
      }
      else {
        return false;
      }
    }

    render() {
        return (
            <Dialog
                open={this.props.openSaveFleet}
                disableEscapeKeyDown
                fullWidth
                maxWidth='sm'>
                <DialogTitle>{_.isNil(this.props.fleetToSave.id) ? 'Create' : 'Edit'} Fleet</DialogTitle>
                <DialogContent sx={{minHeight: '420px', display: 'flex', flexFlow: 'column nowrap'}}>
                    <Box sx={{display: 'flex', flexFlow: 'column nowrap', width: '100%', flexGrow: 1}}>
                      <Tabs
                          value={this.props.saveFleetTabIndex}
                          onChange={(event, value) => this.props.onSetSaveFleetTabIndex(value)}
                          indicatorColor='primary'
                          textColor='primary'
                          variant={'fullWidth'}>
                        <Tab value={0} label='Definition' iconPosition="end" 
                          icon={
                            (!_.isNil(this.props.saveStatus.fleetOk) ? 
                              (this.props.saveStatus.fleetOk === true ? 
                                <Tooltip title={'Fleet Definition saved'}><CheckCircleOutlineIcon/></Tooltip> :  <Tooltip title={'Fleet Definition Not Saved'}><HighlightOffIcon/></Tooltip>  ) : 
                            null )
                          }
                        />
                        <Tab value={1} label='Datasources' iconPosition="end" 
                          icon={(this.props.datasourcesChanged === true && _.isNil(this.props.saveStatus.datasourcesOk)) ? 
                            <Tooltip title={'Please review your Datasource changes before saving'}><WarningAmberIcon sx={{color: '#ce4300'}} /></Tooltip> : 
                            (!_.isNil(this.props.saveStatus.datasourcesOk) ? 
                              (this.props.saveStatus.datasourcesOk === true ? 
                                <Tooltip title={'Datasources saved'}><CheckCircleOutlineIcon/></Tooltip> :  <Tooltip title={'Datasources Not Saved'}><HighlightOffIcon/></Tooltip>  ) : 
                            null )
                          } 
                        />
                      </Tabs>
                      {
                        this.props.saveFleetTabIndex === 0 ? definitionTabContent(this.props) : datasourcesTabContent(this.props)
                      }                      
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Box sx={{display: 'flex', flexFlow: 'column nowrap', width: '100%'}}>
                      <Box sx={{display: 'flex', flexFlow: 'row nowrap', flexGrow: 1, justifyContent: 'flex-start'}}>
                        {
                          this.props.saveFleetTabIndex === 1 &&
                          <SplitButton stateDef={this.props.stateDef} />
                        }
                        <Box sx={{display: 'flex', flexFlow: 'row nowrap', flexGrow: 1, justifyContent: 'flex-end'}}>
                          <Button sx={{...dialogStyles.actionButton, marginRight: 2}} variant={'contained'}
                                onClick={() => this.props.onCloseSaveFleetDialog()}>Cancel</Button>
                          <Button variant={'contained'} color='primary' onClick={() => this.props.onSaveFleet(this.props.fleetToSave)}
                                  disabled={this.shouldDisableSaveButton()}>Save</Button>
                        </Box>
                      </Box>
                      {
                        /* There maybe no producer to restart for a new fleet so only show this on existing fleets  */
                        this.props.datasourcesChanged === true &&
                        !_.isNil(this.props.fleetToSave.id) &&
                        <Alert severity='warning' variant='standard' sx={{marginTop: 2}}>Please Restart the Producer for your Datasource changes to take effect</Alert>
                      }
                    </Box>
                </DialogActions>
            </Dialog>
        )
    }
}

const stateDefinition = (props) => {
    return {
        stateDef: {
            key: _.isNil(props.stateKey) ? ComponentTypes.FLEET_MANAGEMENT : props.stateKey,
            type: ComponentTypes.FLEET_MANAGEMENT,
        }
    }
};

const mapStateToProps = (state, props) => {
    const {stateDef} = props;
    const componentState = fleetMgmtState(state[stateDef.key]);
    const appState = applicationState(state);

    return {
        openSaveFleet: componentState.openSaveFleet,
        fleetToSave: componentState.fleetToSave,
        errorsOnFleetSave: componentState.errorsOnFleetSave,
        fleetHasChanges: componentState.fleetHasChanges,

        saveFleetTabIndex: componentState.saveFleetTabIndex,
        datasources: componentState.datasources,
        datasourceTemplates: componentState.datasourceTemplates,
        datasourcesChanged: componentState.datasourcesChanged,
        datasourceSourceTypes: componentState.datasourceSourceTypes,
        saveStatus: componentState.saveStatus,
        
        ownerId: _.isNil(appState.selectedOwner) ? appState.user.ownerId : appState.selectedOwner.value,
        datavanNameHelperText: componentState.datavanNameHelperText,
        warningDatavanOnFleetSave: componentState.warningDatavanOnFleetSave,
    }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onCloseSaveFleetDialog: () => {
      dispatch(fleetMgmtActions.onCloseSaveFleetDialog(props.stateDef))
    },
    onSaveFleet: (fleet) => {
      dispatch(fleetMgmtActions.onSaveFleet(props.stateDef, fleet))
    },
    onChangeFleet: (property, value) => {
      dispatch(fleetMgmtActions.onChangeFleet(props.stateDef, property, value))
    },
    onSetSaveFleetTabIndex: (index) => {
      dispatch(fleetMgmtActions.setSaveFleetTabIndex(props.stateDef, index));
    },
    onUpdateDatasource: (index, property, value) => {
      dispatch(fleetMgmtActions.updateDatasource(props.stateDef, index, property, value));
    },
    onRemoveDatasource: (index) => {
      dispatch(fleetMgmtActions.removeDatasource(props.stateDef, index));
    },
    onRestoreDatasource: (index) => {
      dispatch(fleetMgmtActions.restoreDatasource(props.stateDef, index));
    },
    onConfirmRemoveDatasource: (index, value) => {
      dispatch(fleetMgmtActions.confirmRemoveDatasource(props.stateDef, index, value));
    },
    onExpandDatasource: (index) => {
      dispatch(fleetMgmtActions.expandDatasource(props.stateDef, index));
    },
    onBlurDatavan: (datavanName, ownerId) => {
      dispatch(fleetMgmtActions.onBlurDatavan(props.stateDef, datavanName, ownerId));
    }
  }
};

export default compose(
    withProps(stateDefinition)
)(connect(mapStateToProps, mapDispatchToProps)(SaveFleetDialog))