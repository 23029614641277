import React, { Component } from "react";
import { Box, Button, Typography } from "@mui/material";
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { connect } from "react-redux";
import { compose, withProps } from "recompose";
import PropTypes from 'prop-types';
import getDetailsPageStyles from "../../common/styles/detailsPageStyles";
import MDTDataGrid from "../../common/table/MDTDataGrid";
import { gridClasses } from "@mui/x-data-grid-pro";
import componentTypes from "../../componentTypes";
import Progress from "../../controls/progress";
import * as routeAssetMgmtActions from "../../../state/displays/routeAssetManagement/actions/routeAssetMgmtDisplayActions";
import RouteAssetManagementDeleteConfirmationDialog from "./routeAssetManagementDeleteConfirmationDialog";
import RouteAssetManagementRouteAssetDialog from "./routeAssetManagementRouteAssetDialog";
import RouteAssetManagementConsumerDialog from "./routeAssetManagementConsumerDialog";
import { routeAssetMgmtState } from "../../../state/displays/routeAssetManagement/routeAssetMgmtSelectors";
import { appState as applicationState } from '../../../state/app/appSelectors';

const withWidth = () => (WrappedComponent) => (props) => <WrappedComponent {...props} width="xs" />;

const detailsPageStyles = getDetailsPageStyles();

const styles = {
    ...detailsPageStyles,
    exportAnchor: {
        visibility: 'hidden'
    },
    detailsPageContent: {
        ...detailsPageStyles.detailsPageContent,
        width: '98vw'
    },
    detailsPageHeaderActions: {
        ...detailsPageStyles.detailsPageHeaderActions,
        justifyContent: 'flex-start'
    },
    mdtDataGridContainer: {
        '& .MuiDataGrid-actionsCell': {
            visibility: 'hidden'
        },
        [`& .${gridClasses.row}:hover .MuiDataGrid-actionsCell`]: {
            visibility: 'visible'
        },
    },
    addRouteAssetButton: {
        marginLeft: '5px',
        display: 'flex',
    },
    addRouteAssetIcon: {
        marginRight: '8px',
    },
    rowActions: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    tableActions: {
        height: '64px',
        width: '100%',
    },
    iconButton: {
        padding: 0,
    },
    dateText: {
        flex: 1,
        textAlign: 'center',
    },
};

class RouteAssetMgmtPage extends Component {
    constructor(props) {
        super(props);
        this.columns = this.generateTableColumns();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedOwner !== this.props.selectedOwner) {
            this.props.loadDisplay();
        }
    }

    componentDidMount() {
        this.props.loadDisplay();
    }

    generateTableColumns() {
        let columns = [];

        columns.push({
            field: 'truckName',
            headerName: 'Truck Name',
            headerAlign: 'left',
            align: 'left',
            flex: 1,
            editable: false,
            hideable: false,
            pinnable: false
        });

        columns.push({
            field: 'consumerName',
            headerName: 'Consumer Name',
            headerAlign: 'left',
            align: 'left',
            flex: 1,
            editable: false,
            hideable: false,
            pinnable: false
        });

        columns.push({
          field: 'destinationProject',
          headerName: 'Destination Project',
          headerAlign: 'left',
          align: 'left',
          flex: 1,
          editable: false,
          hideable: false,
          pinnable: false
        });

        columns.push({
            field: 'destinationPath',
            headerName: 'Destination Path',
            headerAlign: 'left',
            align: 'left',
            flex: 1,
            editable: false,
            hideable: false,
            pinnable: false
        });

        columns.push({
            field: 'Actions',
            align: 'center',
            resizable: false,
            hideable: false,
            disableReorder: true,
            type: 'actions',
            getActions: (params) => {
                const actions = [];
                actions.push(<GridActionsCellItem icon={<EditIcon fontSize={'small'} />}
                    onClick={() => { 
                        this.props.openEditDialog(params.row); 
                    }}
                    label="Edit" showInMenu />);
                if (params.row?.isEnabled) {
                    actions.push(<GridActionsCellItem icon={<CloseIcon fontSize={'small'} />}
                        onClick={() => this.props.setActiveState(params.row, false)}
                        label="Disable" showInMenu />);
                }
                actions.push(<GridActionsCellItem icon={<DeleteForeverIcon fontSize={'small'} />}
                    onClick={() => { 
                        this.props.openDeleteConfirmation(params.row);
                    }}
                    label="Delete" showInMenu />);
                return actions;
            }
        });

        return columns;
    }

    render() {
        return (
            <Box sx={styles.detailsPage}>
                <Box sx={styles.detailsPageContent}>
                    <Box sx={styles.detailsPageHeader}>
                        <Box sx={styles.detailsPageHeaderTitle}>
                            <Typography variant="h6">Asset Routing</Typography>
                        </Box>
                        <Box sx={styles.detailsPageHeaderActions}>
                            <Button onClick={() => this.props.openAddRouteAssetDialog()} sx={styles.addRouteAssetButton} color="inherit">
                                <PersonAddIcon sx={styles.addRouteAssetIcon} />
                                ROUTE AN ASSET
                            </Button>
                            <Button onClick={() => this.props.openAddConsumerDialog()} sx={styles.addRouteAssetButton} color="inherit">
                                <PersonAddIcon sx={styles.addRouteAssetIcon} />
                                ADD CONSUMER
                            </Button>
                        </Box>
                    </Box>
                    <Box sx={styles.mdtDataGridContainer}>
                        <MDTDataGrid
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'truckName', sort: 'asc' }],
                                }
                            }}
                            columns={this.columns}
                            rows={this.props.routeAssets}
                            disableSelectionOnClick
                            componentsProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    printOptions: {
                                        disableToolbarButton: true
                                    }
                                }
                            }}
                            stateDef={this.props.stateDef}
                            rowsPerPage={this.props.routeAssetsPerPage}
                            setRowsPerPage={this.props.setRouteAssetsPerPage}
                            setCurrentPage={this.props.setCurrentPage}
                            rowsPerPageOptions={this.props.routeAssetsPerPageOptions}
                        />
                    </Box>
                </Box>

                <RouteAssetManagementRouteAssetDialog open={this.props.openAddRouteAssetUI || this.props.openEditRouteAssetUI} />

                <RouteAssetManagementDeleteConfirmationDialog open={this.props.openDeleteRouteAssetUI} />

                <RouteAssetManagementConsumerDialog />

                <Progress open={this.props.queryRunning} />

            </Box>
        )
    }
}

RouteAssetMgmtPage.propTypes = {
    width: PropTypes.string.isRequired,
};

const stateDefinition = (props) => {
    return {
        stateDef: {
            key: _.isNil(props.stateKey) ? componentTypes.ROUTE_ASSET_MANAGEMENT : props.stateKey,
            type: componentTypes.ROUTE_ASSET_MANAGEMENT,
        }
    }
};

const mapStateToProps = (state, props) => {
    const { stateDef } = props;
    let componentState = routeAssetMgmtState(state[stateDef.key]);
    let appState = applicationState(state);
    return {
        selectedOwner: appState.selectedOwner,
        queryRunning: componentState.queryRunning,
        routeAssets: componentState.routeAssets,
        consumers: componentState.consumers,
        trucks: componentState.trucks,
        routeAssetsPerPage: componentState.routeAssetsPerPage,
        currentPage: componentState.currentPage,
        workingRouteAsset: componentState.workingRouteAsset,
        openAddRouteAssetUI: componentState.openAddRouteAssetUI,
        openEditRouteAssetUI: componentState.openEditRouteAssetUI,
        openDeleteRouteAssetUI: componentState.openDeleteRouteAssetUI,
        user: appState.user,
        routeAssetsPerPageOptions: componentState.routeAssetsPerPageOptions
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        setRouteAssetsPerPage: (routeAssetsPerPage) => dispatch(routeAssetMgmtActions.setRouteAssetsPerPage(props.stateDef, routeAssetsPerPage)),
        setCurrentPage: (currentPage) => dispatch(routeAssetMgmtActions.setCurrentPage(props.stateDef, currentPage)),
        openAddConsumerDialog: () => dispatch(routeAssetMgmtActions.openAddConsumerDialog(props.stateDef)),
        closeAddConsumerDialog: () => dispatch(routeAssetMgmtActions.closeAddConsumerDialog(props.stateDef)),
        openAddRouteAssetDialog: () => dispatch(routeAssetMgmtActions.openAddRouteAssetDialog(props.stateDef)),
        setActiveState: () => dispatch(routeAssetMgmtActions.setActiveState(props.stateDef)),
        openEditDialog: (routeAsset) => dispatch(routeAssetMgmtActions.openEditDialog(props.stateDef, routeAsset)),
        openDeleteConfirmation: (routeAsset) => dispatch(routeAssetMgmtActions.openDeleteConfirmation(props.stateDef, routeAsset)),
        loadDisplay: () => dispatch(routeAssetMgmtActions.loadDisplay(props.stateDef)),
    };
};

export default compose(
    withWidth(),
    withProps(stateDefinition),
    connect(mapStateToProps, mapDispatchToProps)
)(RouteAssetMgmtPage);