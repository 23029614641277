import routeAssetMgmtActionTypes from "../routeAssetMgmtActionTypes";
import * as routeAssetMgmtServices from '../services/routeAssetMgmtServices';
import { routeAssetMgmtState } from "../routeAssetMgmtSelectors";

const initialState = routeAssetMgmtState();

const routeAssetMgmtDeleteConfirmationReducer = (state, action) => {
    switch (action.type) {
        case routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_DELETE_ROUTE_ASSET_STARTING:
            return { ...state, queryRunning: true };
        case routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_DELETE_ROUTE_ASSET_SUCCESS:
            return { ...state, queryRunning: false, openDeleteRouteAssetUI: false };
        case routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_DELETE_ROUTE_ASSET_ERROR:
            return { ...state, queryRunning: false };
        case routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_OPEN_DELETE_CONFIRMATION:
            return { ...state, openDeleteRouteAssetUI: true, workingRouteAsset: routeAssetMgmtServices.processRouteAsset(action.routeAsset) };
        case routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_CLOSE_DELETE_CONFIRMATION:
            return { ...state, openDeleteRouteAssetUI: false, workingRouteAsset: initialState.workingRouteAsset };
        default:
            return state;
    }
};

export default routeAssetMgmtDeleteConfirmationReducer;
