const routeAssets = [
    { id: 1, truckName: 'Truck 1', consumerName: 'Jereh_Test', consumerId: 1, destinationPath: 'jerehTestTopic', destinationProject: 'molten-raceway-216020' },
    { id: 2, truckName: 'Truck 2', consumerName: 'Consumer 2', consumerId: 2, destinationPath: 'Path 2', destinationProject: 'project-2-99999' },
    { id: 3, truckName: 'Truck 3', consumerName: 'Consumer 3', consumerId: 3, destinationPath: 'Path 3', destinationProject: 'project-3-88888' },
    { id: 4, truckName: 'Truck 4', consumerName: 'Consumer 4', consumerId: 4, destinationPath: 'Path 4', destinationProject: 'project-4-77777' },
    { id: 5, truckName: 'Truck 5', consumerName: 'Consumer 5', consumerId: 5, destinationPath: 'Path 5', destinationProject: 'project-5-66666' }
];

const trucks = [
    { name: 'Truck 1'},
    { name: 'Truck 2'},
    { name: 'Truck 3'},
    { name: 'Truck 4'},
    { name: 'Truck 5'},
    { name: 'Truck 6'},
    { name: 'Truck 7'},
    { name: 'Truck 8'},
]; 

const consumers = [
    { id: 1, consumerName: 'Jereh_Test' },
    { id: 2, consumerName: 'Consumer 2' },
    { id: 3, consumerName: 'Consumer 3' },
    { id: 4, consumerName: 'Consumer 4' },
    { id: 5, consumerName: 'Consumer 5' },
    { id: 6, consumerName: 'Consumer 6' },
    { id: 7, consumerName: 'Consumer 7' }
];

export { trucks, routeAssets, consumers };
